export const NOTIFICATIONS_STORE_MODULE_NAME = 'notifications';

export const NOTIFICATIONS_MUTATIONS = {
  setForceReload: `${NOTIFICATIONS_STORE_MODULE_NAME}/setForceReload`,
  SET_NOTIFICATIONS: `${NOTIFICATIONS_STORE_MODULE_NAME}/setNotifications`,
  setUnreadCount: `${NOTIFICATIONS_STORE_MODULE_NAME}/setUnreadCount`,
  increaseUnreadCount: `${NOTIFICATIONS_STORE_MODULE_NAME}/increaseUnreadCount`,
  decreaseUnreadCount: `${NOTIFICATIONS_STORE_MODULE_NAME}/decreaseUnreadCount`,
  resetNotifications: `${NOTIFICATIONS_STORE_MODULE_NAME}/resetNotifications`,
};

export const NOTIFICATIONS_ACTIONS = {
  initializeServiceWorker: `${NOTIFICATIONS_STORE_MODULE_NAME}/initializeServiceWorker`,
  toggleNotifications: `${NOTIFICATIONS_STORE_MODULE_NAME}/toggleNotifications`,
  notificationDismissed: `${NOTIFICATIONS_STORE_MODULE_NAME}/notificationDismissed`,
  allNotificationsDismissed: `${NOTIFICATIONS_STORE_MODULE_NAME}/allNotificationsDismissed`,
  requestForceReload: `${NOTIFICATIONS_STORE_MODULE_NAME}/requestForceReload`,
  unreadCount: `${NOTIFICATIONS_STORE_MODULE_NAME}/unreadCount`,
};

export const NOTIFICATIONS_GETTERS = {
  isSubscribedToPushNotifications: `${NOTIFICATIONS_STORE_MODULE_NAME}/isSubscribedToPushNotifications`,
  unreadCount: `${NOTIFICATIONS_STORE_MODULE_NAME}/unreadCount`,
  forceReload: `${NOTIFICATIONS_STORE_MODULE_NAME}/forceReload`,
  notifications: `${NOTIFICATIONS_STORE_MODULE_NAME}/notifications`,
};
